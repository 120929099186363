<div class="table-responsive scroll-table bg-white">
    <table class="table table-bordered basic-table">
        <thead>
            <tr class="row m-0">
                <ng-container  *ngFor="let head of table_data.head | orderBy: 'sort_seqno'">
                    <th [ngClass]="checkSize(head.field)">{{head.name}}</th>
                </ng-container>
            </tr>
        </thead>
        <tbody>
            <tr class="row m-0" *ngFor="let data of table_data.data" >
                <ng-container *ngFor="let head of table_data.head | orderBy: 'sort_seqno'">
                    <td [ngClass]="checkSize(head.field)">{{data[head['field']]}}</td>
                </ng-container>
            </tr> 
        </tbody>
    </table> 
</div>