import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-basic-table',
  templateUrl: './basic-table.component.html',
  styleUrls: ['./basic-table.component.scss']
})
export class BasicTableComponent implements OnInit {
  @Input() table_data: any; 

  list: any = {
    cmd_value : 'col-4' ,
    msg_date: 'col-3',
    msg_time: 'col-3',
    status: 'col-2',
    cmd_data: 'col-6',
    timestamp: 'col-2',
    location: 'col-3',
    'BMS Communication': 'col-2',
    data: 'col-5',
    string: 'col-3',
    id: 'col-2'
  }
  constructor() { }

  ngOnInit(): void {
    
  }

  checkSize(key: string): string{

    if(this.list.hasOwnProperty(key))
      return this.list[key];
    
    return 'col-1';
  }
}
